import styled from 'styled-components';

const Table = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: table;
    border-spacing: 0 0.5em;
  }
`;

export default Table;
