import styled from 'styled-components';
import Tr from './Tr';
import Td from './Td';

const Thr = styled(Tr)`
  font-weight: 600;
  display: none;
  border: none;

  ${Td} {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }

  ${Td}:first-child {
    padding-left: 0;
  }
`;

export default Thr;
