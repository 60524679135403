import styled from 'styled-components';

const Td = styled.div`
  flex: 1 1 auto;
  padding: 0.6em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: table-cell;
    border-top: 0.125em solid ${({ theme }) => theme.colors.neutral4};
    border-bottom: 0.125em solid ${({ theme }) => theme.colors.neutral4};

    &:first-child {
      border-left: 0.125em solid ${({ theme }) => theme.colors.neutral4};
      border-top-left-radius: 0.25em;
      border-bottom-left-radius: 0.25em;
    }

    &:last-child {
      border-right: 0.125em solid ${({ theme }) => theme.colors.neutral4};
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
    }
  }
`;

export default Td;
