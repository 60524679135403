import styled from 'styled-components';

const Tr = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: table-row;
  }
`;

export default Tr;
